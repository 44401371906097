<!-- Przycisk otwierający panel aplikacji -->
<p-button pRipple type="button" styleClass="bg-white dark:bg-gray-300 border-0 w-10 h-10 p-2" (onClick)="op.toggle($event)">
  <ng-template pTemplate="icon">
    <img src="../assets/img/apps-list.png" alt="BDO mobile" />
  </ng-template>
</p-button>

<!-- Panel nawigacyjny z sekcjami aplikacji -->
<p-overlayPanel #op>
  <div *ngFor="let section of sections" class="mb-4">
    <h3 class="!mb-0 !font-medium !p-2 !px-6">{{ section.name }}</h3>
    <div *ngFor="let element of section.elements" class="flex items-center py-2 mb-2">
      <a [href]="element.href" target="_blank" class="flex items-center dark:hover:bg-gray-900 hover:bg-gray-100 p-2 px-8">
        <div [ngStyle]="{ background: element.background || 'transparent' }" class="p-1 mr-2 rounded-lg">
          <img [src]="element.icon" alt="{{ element.name }}" class="w-12 h-12 rounded-lg" />
        </div>
        <span class="!font-medium pr-16">{{ element.name }}</span>
      </a>
    </div>
  </div>
</p-overlayPanel>
