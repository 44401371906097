<div class="w-full flex pl-2">
        <div class="w-full flex" #resizableBlock>
            <div class="w-full" [ngClass]="{'flex gap-1': width >= 300}">
                <div class="flex flex-col flex-grow gap-1 w-full">
                    <span class="font-medium text-sm">{{ 'views.app.data.subject' | translate }}</span>
                    <p-skeleton *ngIf="loadingCompanies$ | async" class="w-[200px]" height="3rem"></p-skeleton>
                    <p-dropdown *ngIf="(loadingCompanies$ | async) == false && companies.length == 0" class="!w-full"
                        optionLabel="name" [disabled]="true" [options]="[{ name: 'Brak podmiotów' }]"></p-dropdown>
                    <p-dropdown *ngIf="(loadingCompanies$ | async) == false && companies.length > 0" class="!w-full"
                        [options]="companies" [ngModel]="(selectedCompany$ | async) ?? []"
                        styleClass="!w-full"
                        (ngModelChange)="onChangeCompany($event)" [filter]="true" filterBy="name">
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="flex items-center gap-2">
                                <img src="https://ui-avatars.com/api/?size=128&background=random&name={{ item?.name }}"
                                    class="w-4" [alt]="item?.name" />
                                <div>{{ item?.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="flex items-center gap-2">
                                <img src="https://ui-avatars.com/api/?size=128&background=random&name={{ item?.name }}"
                                    class="w-4" [alt]="item?.name" />
                                <div>{{ item?.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div>
                    <!-- mock dropdown with text 'Brak podmiotów' -->
                    <div class="flex flex-col gap-1" [ngClass]="{'mt-0': width >= 300, 'mt-2': width < 300}">
                        <span class="font-medium text-sm">{{ 'views.app.data.year' | translate }}</span>
                        <form [formGroup]="form">
                            <p-dropdown class="!w-full"
                                [options]="availableYears" formControlName="year"  (onChange)="onChangeYear($event)" styleClass="!w-full">
                            </p-dropdown>
                        </form>
                    </div>
                </div>
            </div>
    </div>