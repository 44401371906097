import { createAction, props } from "@ngrx/store";
import { CompanyUnit } from "../../../core/services/companies/models/company-unit.model";
import { BdoServicePage } from "../../services/companies/models/bdo-service-page.model";
import { Company } from "../../services/companies/models/company.model";


// Load Companies with Pagination
export const loadCompanies = createAction(
  '[Company Context] Load Companies'
);

export const loadCompaniesSuccess = createAction(
  '[Company Context] Load Companies Success',
  props<{ items: Company[] }>()
);

export const loadCompaniesFailure = createAction(
  '[Company Context] Load Companies Failure',
  props<{ error: string }>()
);

// Actions to change and select a company
export const changeSelectedCompany = createAction(
  '[Company Context] Change Selected Company',
  props<{ item: Company}>()
);

// Actions to load company units
export const loadCompanyUnits = createAction(
  '[Company Context] Load Company Units',
  props<{ companyId: string; page: number; size: number; showHidden: boolean }>()
);

export const loadCompanyUnitsSuccess = createAction(
  '[Company Context] Load Company Units Success',
  props<{ page: BdoServicePage<CompanyUnit> }>()
);

export const loadCompanyUnitsFailure = createAction(
  '[Company Context] Load Company Units Failure',
  props<{ error: string }>()
);

export const changeYear = createAction(
  '[Company Context] Change Year',
  props<{ year: number }>()
);