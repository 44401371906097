import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule, SkipSelf } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';

/**
 * Custom HttpParameterCodec
 * Workaround for https://github.com/angular/angular/issues/18261
 */
class CustomHttpParameterCodec {
  encodeKey(k) {
    return encodeURIComponent(k);
  }
  encodeValue(v) {
    return encodeURIComponent(v);
  }
  decodeKey(k) {
    return decodeURIComponent(k);
  }
  decodeValue(v) {
    return decodeURIComponent(v);
  }
}
const BASE_PATH = new InjectionToken('basePath');
const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
};
class Configuration {
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  apiKeys;
  username;
  password;
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  accessToken;
  basePath;
  withCredentials;
  /**
   * Takes care of encoding query- and form-parameters.
   */
  encoder;
  /**
   * Encoding of various path parameter
   * <a href="https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.1.0.md#style-values">styles</a>.
   * <p>
   * See {@link README.md} for more details
   * </p>
   */
  encodeParam;
  /**
   * The keys are the names in the securitySchemes section of the OpenAPI
   * document. They should map to the value used for authentication
   * minus any standard prefixes such as 'Basic' or 'Bearer'.
   */
  credentials;
  constructor(configurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken = configurationParameters.accessToken;
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
    this.encoder = configurationParameters.encoder;
    if (configurationParameters.encodeParam) {
      this.encodeParam = configurationParameters.encodeParam;
    } else {
      this.encodeParam = param => this.defaultEncodeParam(param);
    }
    if (configurationParameters.credentials) {
      this.credentials = configurationParameters.credentials;
    } else {
      this.credentials = {};
    }
    // init default Bearer JWT Token credential
    if (!this.credentials['Bearer JWT Token']) {
      this.credentials['Bearer JWT Token'] = () => {
        return typeof this.accessToken === 'function' ? this.accessToken() : this.accessToken;
      };
    }
  }
  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param contentTypes - the array of content types that are available for selection
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderContentType(contentTypes) {
    if (contentTypes.length === 0) {
      return undefined;
    }
    const type = contentTypes.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }
  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param accepts - the array of content types that are available for selection.
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderAccept(accepts) {
    if (accepts.length === 0) {
      return undefined;
    }
    const type = accepts.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
  lookupCredential(key) {
    const value = this.credentials[key];
    return typeof value === 'function' ? value() : value;
  }
  defaultEncodeParam(param) {
    // This implementation exists as fallback for missing configuration
    // and for backwards compatibility to older typescript-angular generator versions.
    // It only works for the 'simple' parameter style.
    // Date-handling only works for the 'date-time' format.
    // All other styles and Date-formats are probably handled incorrectly.
    //
    // But: if that's all you need (i.e.: the most common use-case): no need for customization!
    const value = param.dataFormat === 'date-time' && param.value instanceof Date ? param.value.toISOString() : param.value;
    return encodeURIComponent(String(value));
  }
}

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class CertificatedRenewableEnergyBoughtEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addCertificatedRenewableEnergyBoughtEntry(addCertificatedRenewableEnergyBoughtRequest, observe = 'body', reportProgress = false, options) {
    if (addCertificatedRenewableEnergyBoughtRequest === null || addCertificatedRenewableEnergyBoughtRequest === undefined) {
      throw new Error('Required parameter addCertificatedRenewableEnergyBoughtRequest was null or undefined when calling addCertificatedRenewableEnergyBoughtEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/certificatedRenewableEnergyBought`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addCertificatedRenewableEnergyBoughtRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteCertificatedRenewableEnergyBoughtEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteCertificatedRenewableEnergyBoughtEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/certificatedRenewableEnergyBought/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getCertificatedRenewableEnergyBoughtEntries(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getCertificatedRenewableEnergyBoughtEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getCertificatedRenewableEnergyBoughtEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/certificatedRenewableEnergyBought`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getCertificatedRenewableEnergyBoughtEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getCertificatedRenewableEnergyBoughtEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/certificatedRenewableEnergyBought/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchCertificatedRenewableEnergyBoughtEntry(id, patchCertificatedRenewableEnergyBoughtRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchCertificatedRenewableEnergyBoughtEntry.');
    }
    if (patchCertificatedRenewableEnergyBoughtRequest === null || patchCertificatedRenewableEnergyBoughtRequest === undefined) {
      throw new Error('Required parameter patchCertificatedRenewableEnergyBoughtRequest was null or undefined when calling patchCertificatedRenewableEnergyBoughtEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/certificatedRenewableEnergyBought/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchCertificatedRenewableEnergyBoughtRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateCertificatedRenewableEnergyBoughtEntry(id, updateCertificatedRenewableEnergyBoughtRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateCertificatedRenewableEnergyBoughtEntry.');
    }
    if (updateCertificatedRenewableEnergyBoughtRequest === null || updateCertificatedRenewableEnergyBoughtRequest === undefined) {
      throw new Error('Required parameter updateCertificatedRenewableEnergyBoughtRequest was null or undefined when calling updateCertificatedRenewableEnergyBoughtEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/certificatedRenewableEnergyBought/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateCertificatedRenewableEnergyBoughtRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function CertificatedRenewableEnergyBoughtEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CertificatedRenewableEnergyBoughtEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CertificatedRenewableEnergyBoughtEntryService,
    factory: CertificatedRenewableEnergyBoughtEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CertificatedRenewableEnergyBoughtEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DictionaryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getList(getDictionaryListRequest, observe = 'body', reportProgress = false, options) {
    if (getDictionaryListRequest === null || getDictionaryListRequest === undefined) {
      throw new Error('Required parameter getDictionaryListRequest was null or undefined when calling getList.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/dictionary`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getDictionaryListRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  update(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/dictionary/install`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DictionaryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DictionaryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DictionaryService,
    factory: DictionaryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DictionaryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ESGConferenceService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addEntryToConference(addEsgConferenceEntryRequest, observe = 'body', reportProgress = false, options) {
    if (addEsgConferenceEntryRequest === null || addEsgConferenceEntryRequest === undefined) {
      throw new Error('Required parameter addEsgConferenceEntryRequest was null or undefined when calling addEntryToConference.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference/entry`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addEsgConferenceEntryRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  addparticipantsToConference(conferenceId, participants, observe = 'body', reportProgress = false, options) {
    if (conferenceId === null || conferenceId === undefined) {
      throw new Error('Required parameter conferenceId was null or undefined when calling addparticipantsToConference.');
    }
    if (participants === null || participants === undefined) {
      throw new Error('Required parameter participants was null or undefined when calling addparticipantsToConference.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (conferenceId !== undefined && conferenceId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, conferenceId, 'conferenceId');
    }
    if (participants) {
      participants.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'participants');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference/participants`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  changeStatus(conferenceId, status, observe = 'body', reportProgress = false, options) {
    if (conferenceId === null || conferenceId === undefined) {
      throw new Error('Required parameter conferenceId was null or undefined when calling changeStatus.');
    }
    if (status === null || status === undefined) {
      throw new Error('Required parameter status was null or undefined when calling changeStatus.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (conferenceId !== undefined && conferenceId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, conferenceId, 'conferenceId');
    }
    if (status !== undefined && status !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, status, 'status');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createConference(createConferenceRequest, observe = 'body', reportProgress = false, options) {
    if (createConferenceRequest === null || createConferenceRequest === undefined) {
      throw new Error('Required parameter createConferenceRequest was null or undefined when calling createConference.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createConferenceRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteConference(conferenceId, observe = 'body', reportProgress = false, options) {
    if (conferenceId === null || conferenceId === undefined) {
      throw new Error('Required parameter conferenceId was null or undefined when calling deleteConference.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (conferenceId !== undefined && conferenceId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, conferenceId, 'conferenceId');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSummaryConference(conferenceId, observe = 'body', reportProgress = false, options) {
    if (conferenceId === null || conferenceId === undefined) {
      throw new Error('Required parameter conferenceId was null or undefined when calling getSummaryConference.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (conferenceId !== undefined && conferenceId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, conferenceId, 'conferenceId');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference/summary`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSummaryConferenceByUser(accessCode, email, observe = 'body', reportProgress = false, options) {
    if (accessCode === null || accessCode === undefined) {
      throw new Error('Required parameter accessCode was null or undefined when calling getSummaryConferenceByUser.');
    }
    if (email === null || email === undefined) {
      throw new Error('Required parameter email was null or undefined when calling getSummaryConferenceByUser.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (accessCode !== undefined && accessCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, accessCode, 'accessCode');
    }
    if (email !== undefined && email !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, email, 'email');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference/summary_by_user`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  removeparticipantsToConference(conferenceId, participants, observe = 'body', reportProgress = false, options) {
    if (conferenceId === null || conferenceId === undefined) {
      throw new Error('Required parameter conferenceId was null or undefined when calling removeparticipantsToConference.');
    }
    if (participants === null || participants === undefined) {
      throw new Error('Required parameter participants was null or undefined when calling removeparticipantsToConference.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (conferenceId !== undefined && conferenceId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, conferenceId, 'conferenceId');
    }
    if (participants) {
      participants.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'participants');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference/participants`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateConference(updateEsgConferenceRequest, observe = 'body', reportProgress = false, options) {
    if (updateEsgConferenceRequest === null || updateEsgConferenceRequest === undefined) {
      throw new Error('Required parameter updateEsgConferenceRequest was null or undefined when calling updateConference.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/conference`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateEsgConferenceRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ESGConferenceService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ESGConferenceService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ESGConferenceService,
    factory: ESGConferenceService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ESGConferenceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ElectricityConsumptionEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addElectricityConsumptionEntry(addElectricityConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (addElectricityConsumptionRequest === null || addElectricityConsumptionRequest === undefined) {
      throw new Error('Required parameter addElectricityConsumptionRequest was null or undefined when calling addElectricityConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/electricityConsumption`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addElectricityConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteElectricityConsumptionEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteElectricityConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/electricityConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getElectricityConsumptionEntries(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getElectricityConsumptionEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getElectricityConsumptionEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/electricityConsumption`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getElectricityConsumptionEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getElectricityConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/electricityConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchElectricityConsumptionEntry(id, patchElectricityConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchElectricityConsumptionEntry.');
    }
    if (patchElectricityConsumptionRequest === null || patchElectricityConsumptionRequest === undefined) {
      throw new Error('Required parameter patchElectricityConsumptionRequest was null or undefined when calling patchElectricityConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/electricityConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchElectricityConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateElectricityConsumptionEntry(id, updateElectricityConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateElectricityConsumptionEntry.');
    }
    if (updateElectricityConsumptionRequest === null || updateElectricityConsumptionRequest === undefined) {
      throw new Error('Required parameter updateElectricityConsumptionRequest was null or undefined when calling updateElectricityConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/electricityConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateElectricityConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ElectricityConsumptionEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ElectricityConsumptionEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ElectricityConsumptionEntryService,
    factory: ElectricityConsumptionEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ElectricityConsumptionEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class FugitiveEmissionsEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addFugitiveEmission(addFugitiveEmissionRequest, observe = 'body', reportProgress = false, options) {
    if (addFugitiveEmissionRequest === null || addFugitiveEmissionRequest === undefined) {
      throw new Error('Required parameter addFugitiveEmissionRequest was null or undefined when calling addFugitiveEmission.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/fugitiveEmissions`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addFugitiveEmissionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteFugitiveEmission(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteFugitiveEmission.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/fugitiveEmissions/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getFugitiveEmission(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getFugitiveEmission.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/fugitiveEmissions/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getFugitiveEmissions(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getFugitiveEmissions.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getFugitiveEmissions.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/fugitiveEmissions`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchFugitiveEmission(id, patchFugitiveEmissionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchFugitiveEmission.');
    }
    if (patchFugitiveEmissionRequest === null || patchFugitiveEmissionRequest === undefined) {
      throw new Error('Required parameter patchFugitiveEmissionRequest was null or undefined when calling patchFugitiveEmission.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/fugitiveEmissions/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchFugitiveEmissionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateFugitiveEmission(id, updateFugitiveEmissionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateFugitiveEmission.');
    }
    if (updateFugitiveEmissionRequest === null || updateFugitiveEmissionRequest === undefined) {
      throw new Error('Required parameter updateFugitiveEmissionRequest was null or undefined when calling updateFugitiveEmission.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/fugitiveEmissions/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateFugitiveEmissionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function FugitiveEmissionsEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FugitiveEmissionsEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: FugitiveEmissionsEntryService,
    factory: FugitiveEmissionsEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FugitiveEmissionsEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class HeatConsumptionEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addHeatConsumptionEntry(addHeatConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (addHeatConsumptionRequest === null || addHeatConsumptionRequest === undefined) {
      throw new Error('Required parameter addHeatConsumptionRequest was null or undefined when calling addHeatConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/heatConsumption`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addHeatConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteHeatConsumptionEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteHeatConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/heatConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getHeatConsumptionEntries(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getHeatConsumptionEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getHeatConsumptionEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/heatConsumption`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getHeatConsumptionEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getHeatConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/heatConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchHeatConsumptionEntry(id, patchHeatConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchHeatConsumptionEntry.');
    }
    if (patchHeatConsumptionRequest === null || patchHeatConsumptionRequest === undefined) {
      throw new Error('Required parameter patchHeatConsumptionRequest was null or undefined when calling patchHeatConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/heatConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchHeatConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateHeatConsumptionEntry(id, updateHeatConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateHeatConsumptionEntry.');
    }
    if (updateHeatConsumptionRequest === null || updateHeatConsumptionRequest === undefined) {
      throw new Error('Required parameter updateHeatConsumptionRequest was null or undefined when calling updateHeatConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/heatConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateHeatConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function HeatConsumptionEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || HeatConsumptionEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: HeatConsumptionEntryService,
    factory: HeatConsumptionEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeatConsumptionEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ManualCompaniesCO2ScoreEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addManualCompaniesCO2ScoreEntry(addManualCompaniesCO2ScoreRequest, observe = 'body', reportProgress = false, options) {
    if (addManualCompaniesCO2ScoreRequest === null || addManualCompaniesCO2ScoreRequest === undefined) {
      throw new Error('Required parameter addManualCompaniesCO2ScoreRequest was null or undefined when calling addManualCompaniesCO2ScoreEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manualCompaniesCO2Score`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addManualCompaniesCO2ScoreRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteManualCompaniesCO2ScoreEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteManualCompaniesCO2ScoreEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manualCompaniesCO2Score/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getManualCompaniesCO2ScoreEntries(companyId, year, co2ScoreMassUnit, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getManualCompaniesCO2ScoreEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getManualCompaniesCO2ScoreEntries.');
    }
    if (co2ScoreMassUnit === null || co2ScoreMassUnit === undefined) {
      throw new Error('Required parameter co2ScoreMassUnit was null or undefined when calling getManualCompaniesCO2ScoreEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (co2ScoreMassUnit !== undefined && co2ScoreMassUnit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, co2ScoreMassUnit, 'co2ScoreMassUnit');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manualCompaniesCO2Score`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getManualCompaniesCO2ScoreEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getManualCompaniesCO2ScoreEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manualCompaniesCO2Score/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchManualCompaniesCO2ScoreEntry(id, patchManualCompaniesCO2ScoreRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchManualCompaniesCO2ScoreEntry.');
    }
    if (patchManualCompaniesCO2ScoreRequest === null || patchManualCompaniesCO2ScoreRequest === undefined) {
      throw new Error('Required parameter patchManualCompaniesCO2ScoreRequest was null or undefined when calling patchManualCompaniesCO2ScoreEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manualCompaniesCO2Score/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchManualCompaniesCO2ScoreRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateManualCompaniesCO2ScoreEntry(id, updateManualCompaniesCO2ScoreRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateManualCompaniesCO2ScoreEntry.');
    }
    if (updateManualCompaniesCO2ScoreRequest === null || updateManualCompaniesCO2ScoreRequest === undefined) {
      throw new Error('Required parameter updateManualCompaniesCO2ScoreRequest was null or undefined when calling updateManualCompaniesCO2ScoreEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manualCompaniesCO2Score/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateManualCompaniesCO2ScoreRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ManualCompaniesCO2ScoreEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ManualCompaniesCO2ScoreEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ManualCompaniesCO2ScoreEntryService,
    factory: ManualCompaniesCO2ScoreEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ManualCompaniesCO2ScoreEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ManufacturedBottlesEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addManufacturedBottlesEntry(addManufacturedBottlesRequest, observe = 'body', reportProgress = false, options) {
    if (addManufacturedBottlesRequest === null || addManufacturedBottlesRequest === undefined) {
      throw new Error('Required parameter addManufacturedBottlesRequest was null or undefined when calling addManufacturedBottlesEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manufacturedBottles`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addManufacturedBottlesRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteManufacturedBottlesEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteManufacturedBottlesEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manufacturedBottles/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getManufacturedBottlesEntries(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getManufacturedBottlesEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getManufacturedBottlesEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manufacturedBottles`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getManufacturedBottlesEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getManufacturedBottlesEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manufacturedBottles/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchManufacturedBottlesEntry(id, patchManufacturedBottlesRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchManufacturedBottlesEntry.');
    }
    if (patchManufacturedBottlesRequest === null || patchManufacturedBottlesRequest === undefined) {
      throw new Error('Required parameter patchManufacturedBottlesRequest was null or undefined when calling patchManufacturedBottlesEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manufacturedBottles/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchManufacturedBottlesRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateManufacturedBottlesEntry(id, updateManufacturedBottlesRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateManufacturedBottlesEntry.');
    }
    if (updateManufacturedBottlesRequest === null || updateManufacturedBottlesRequest === undefined) {
      throw new Error('Required parameter updateManufacturedBottlesRequest was null or undefined when calling updateManufacturedBottlesEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/manufacturedBottles/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateManufacturedBottlesRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ManufacturedBottlesEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ManufacturedBottlesEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ManufacturedBottlesEntryService,
    factory: ManufacturedBottlesEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ManufacturedBottlesEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class RemoteWorkEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addRemoteWorkEntry(addRemoteWorkRequest, observe = 'body', reportProgress = false, options) {
    if (addRemoteWorkRequest === null || addRemoteWorkRequest === undefined) {
      throw new Error('Required parameter addRemoteWorkRequest was null or undefined when calling addRemoteWorkEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/remoteWork`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addRemoteWorkRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteRemoteWorkEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteRemoteWorkEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/remoteWork/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getRemoteWorkEntries(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getRemoteWorkEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getRemoteWorkEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/remoteWork`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getRemoteWorkEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRemoteWorkEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/remoteWork/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchRemoteWorkEntry(id, patchRemoteWorkRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchRemoteWorkEntry.');
    }
    if (patchRemoteWorkRequest === null || patchRemoteWorkRequest === undefined) {
      throw new Error('Required parameter patchRemoteWorkRequest was null or undefined when calling patchRemoteWorkEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/remoteWork/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchRemoteWorkRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateRemoteWorkEntry(id, updateRemoteWorkRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateRemoteWorkEntry.');
    }
    if (updateRemoteWorkRequest === null || updateRemoteWorkRequest === undefined) {
      throw new Error('Required parameter updateRemoteWorkRequest was null or undefined when calling updateRemoteWorkEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/remoteWork/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateRemoteWorkRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function RemoteWorkEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RemoteWorkEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: RemoteWorkEntryService,
    factory: RemoteWorkEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RemoteWorkEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class RenewableEnergyConsumptionEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addRenewableEnergyConsumptionEntry(addRenewableEnergyConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (addRenewableEnergyConsumptionRequest === null || addRenewableEnergyConsumptionRequest === undefined) {
      throw new Error('Required parameter addRenewableEnergyConsumptionRequest was null or undefined when calling addRenewableEnergyConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/renewableEnergyConsumption`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addRenewableEnergyConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteRenewableEnergyConsumptionEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteRenewableEnergyConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/renewableEnergyConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getRenewableEnergyConsumptionEntries(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getRenewableEnergyConsumptionEntries.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getRenewableEnergyConsumptionEntries.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/renewableEnergyConsumption`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getRenewableEnergyConsumptionEntry(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRenewableEnergyConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/renewableEnergyConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchRenewableEnergyConsumptionEntry(id, patchRenewableEnergyConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchRenewableEnergyConsumptionEntry.');
    }
    if (patchRenewableEnergyConsumptionRequest === null || patchRenewableEnergyConsumptionRequest === undefined) {
      throw new Error('Required parameter patchRenewableEnergyConsumptionRequest was null or undefined when calling patchRenewableEnergyConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/renewableEnergyConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchRenewableEnergyConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateRenewableEnergyConsumptionEntry(id, updateRenewableEnergyConsumptionRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateRenewableEnergyConsumptionEntry.');
    }
    if (updateRenewableEnergyConsumptionRequest === null || updateRenewableEnergyConsumptionRequest === undefined) {
      throw new Error('Required parameter updateRenewableEnergyConsumptionRequest was null or undefined when calling updateRenewableEnergyConsumptionEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/renewableEnergyConsumption/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateRenewableEnergyConsumptionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function RenewableEnergyConsumptionEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RenewableEnergyConsumptionEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: RenewableEnergyConsumptionEntryService,
    factory: RenewableEnergyConsumptionEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RenewableEnergyConsumptionEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ReportEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addReport(annualReportDto, observe = 'body', reportProgress = false, options) {
    if (annualReportDto === null || annualReportDto === undefined) {
      throw new Error('Required parameter annualReportDto was null or undefined when calling addReport.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/reports`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: annualReportDto,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteVehicle(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteVehicle.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/reports/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getReports(companyId, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getReports.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/reports`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ReportEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ReportEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ReportEntryService,
    factory: ReportEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReportEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class StationaryDeviceEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addStationaryDevice(addStationaryDeviceRequest, observe = 'body', reportProgress = false, options) {
    if (addStationaryDeviceRequest === null || addStationaryDeviceRequest === undefined) {
      throw new Error('Required parameter addStationaryDeviceRequest was null or undefined when calling addStationaryDevice.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/stationaryDevice`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addStationaryDeviceRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteDevice(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteDevice.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/stationaryDevice/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getStationaryDevice(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getStationaryDevice.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/stationaryDevice/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getStationaryDevices(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getStationaryDevices.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getStationaryDevices.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/stationaryDevice`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchStationaryDevice(id, patchStationaryDeviceRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchStationaryDevice.');
    }
    if (patchStationaryDeviceRequest === null || patchStationaryDeviceRequest === undefined) {
      throw new Error('Required parameter patchStationaryDeviceRequest was null or undefined when calling patchStationaryDevice.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/stationaryDevice/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchStationaryDeviceRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateStationaryDevice(id, updateStationaryDeviceRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateStationaryDevice.');
    }
    if (updateStationaryDeviceRequest === null || updateStationaryDeviceRequest === undefined) {
      throw new Error('Required parameter updateStationaryDeviceRequest was null or undefined when calling updateStationaryDevice.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/stationaryDevice/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateStationaryDeviceRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function StationaryDeviceEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || StationaryDeviceEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: StationaryDeviceEntryService,
    factory: StationaryDeviceEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StationaryDeviceEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class SummaryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getAllScopes(getSummaryAllScopesRequest, observe = 'body', reportProgress = false, options) {
    if (getSummaryAllScopesRequest === null || getSummaryAllScopesRequest === undefined) {
      throw new Error('Required parameter getSummaryAllScopesRequest was null or undefined when calling getAllScopes.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/summary/allscopes`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getSummaryAllScopesRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getCurrentAndOzeRatio(companyId, year, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getCurrentAndOzeRatio.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getCurrentAndOzeRatio.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/summary/scope2/currentAndOzeRatio`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getFirstScope(getSummaryFirstScopeRequest, observe = 'body', reportProgress = false, options) {
    if (getSummaryFirstScopeRequest === null || getSummaryFirstScopeRequest === undefined) {
      throw new Error('Required parameter getSummaryFirstScopeRequest was null or undefined when calling getFirstScope.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/summary/firstScope`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getSummaryFirstScopeRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getSecondScope(getSummarySecondScopeRequest, observe = 'body', reportProgress = false, options) {
    if (getSummarySecondScopeRequest === null || getSummarySecondScopeRequest === undefined) {
      throw new Error('Required parameter getSummarySecondScopeRequest was null or undefined when calling getSecondScope.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/summary/secondScope`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getSummarySecondScopeRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getThirdScope(getSummaryThirdScopeRequest, observe = 'body', reportProgress = false, options) {
    if (getSummaryThirdScopeRequest === null || getSummaryThirdScopeRequest === undefined) {
      throw new Error('Required parameter getSummaryThirdScopeRequest was null or undefined when calling getThirdScope.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/summary/thirdScope`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getSummaryThirdScopeRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getTopCompaniesByCarbonEmissions(companyId, year, co2ScoreMassUnit, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getTopCompaniesByCarbonEmissions.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getTopCompaniesByCarbonEmissions.');
    }
    if (co2ScoreMassUnit === null || co2ScoreMassUnit === undefined) {
      throw new Error('Required parameter co2ScoreMassUnit was null or undefined when calling getTopCompaniesByCarbonEmissions.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (co2ScoreMassUnit !== undefined && co2ScoreMassUnit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, co2ScoreMassUnit, 'co2ScoreMassUnit');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/summary/scope3/topCompanies`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function SummaryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SummaryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SummaryService,
    factory: SummaryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SummaryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class VehicleEntryService {
  httpClient;
  basePath = 'http://localhost:8096/esg';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  addVehicle(addVehicleRequest, observe = 'body', reportProgress = false, options) {
    if (addVehicleRequest === null || addVehicleRequest === undefined) {
      throw new Error('Required parameter addVehicleRequest was null or undefined when calling addVehicle.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/vehicle`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: addVehicleRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deleteVehicle1(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteVehicle1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/vehicle/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getVehicle(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getVehicle.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/vehicle/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getVehicles(companyId, year, query, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling getVehicles.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling getVehicles.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/vehicle`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  patchVehicleEntry(id, patchVehicleRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchVehicleEntry.');
    }
    if (patchVehicleRequest === null || patchVehicleRequest === undefined) {
      throw new Error('Required parameter patchVehicleRequest was null or undefined when calling patchVehicleEntry.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/vehicle/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchVehicleRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  updateVehicle(id, updateVehicleRequest, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateVehicle.');
    }
    if (updateVehicleRequest === null || updateVehicleRequest === undefined) {
      throw new Error('Required parameter updateVehicleRequest was null or undefined when calling updateVehicle.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/entries/vehicle/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "uuid"
    })}`;
    return this.httpClient.request('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateVehicleRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function VehicleEntryService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || VehicleEntryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: VehicleEntryService,
    factory: VehicleEntryService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VehicleEntryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
const APIS = [CertificatedRenewableEnergyBoughtEntryService, DictionaryService, ESGConferenceService, ElectricityConsumptionEntryService, FugitiveEmissionsEntryService, HeatConsumptionEntryService, ManualCompaniesCO2ScoreEntryService, ManufacturedBottlesEntryService, RemoteWorkEntryService, RenewableEnergyConsumptionEntryService, ReportEntryService, StationaryDeviceEntryService, SummaryService, VehicleEntryService];

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddCertificatedRenewableEnergyBoughtRequest;
(function (AddCertificatedRenewableEnergyBoughtRequest) {
  AddCertificatedRenewableEnergyBoughtRequest.CertificatedRenewableEnergyBoughtUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(AddCertificatedRenewableEnergyBoughtRequest || (AddCertificatedRenewableEnergyBoughtRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddElectricityConsumptionRequest;
(function (AddElectricityConsumptionRequest) {
  AddElectricityConsumptionRequest.EnergySupplierEnum = {
    Enea: 'ENEA',
    Energa: 'ENERGA',
    Eon: 'EON',
    Tauron: 'TAURON',
    Pge: 'PGE',
    Unknown: 'UNKNOWN'
  };
})(AddElectricityConsumptionRequest || (AddElectricityConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddEsgConferenceEntryRequest;
(function (AddEsgConferenceEntryRequest) {
  AddEsgConferenceEntryRequest.VehicleTypeEnum = {
    WalkOrBike: 'WALK_OR_BIKE',
    Scooter: 'SCOOTER',
    Bus: 'BUS',
    Tram: 'TRAM',
    Metro: 'METRO',
    Car: 'CAR',
    Plane: 'PLANE',
    Ship: 'SHIP'
  };
  AddEsgConferenceEntryRequest.FuelTypeEnum = {
    Lpg: 'LPG',
    Petrol: 'PETROL',
    Diesel: 'DIESEL',
    Cng: 'CNG',
    Lng: 'LNG',
    Biodiesel: 'BIODIESEL',
    Bioethanol: 'BIOETHANOL'
  };
})(AddEsgConferenceEntryRequest || (AddEsgConferenceEntryRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddFugitiveEmissionRequest;
(function (AddFugitiveEmissionRequest) {
  AddFugitiveEmissionRequest.CoolingDeviceTypeEnum = {
    VehicleAirConditioner: 'VEHICLE_AIR_CONDITIONER',
    RefrigerationDevice: 'REFRIGERATION_DEVICE',
    StationaryAirConditioner: 'STATIONARY_AIR_CONDITIONER',
    IndustrialProcessCooling: 'INDUSTRIAL_PROCESS_COOLING',
    RefrigeratedTransport: 'REFRIGERATED_TRANSPORT',
    RefrigeratedWarehouses: 'REFRIGERATED_WAREHOUSES'
  };
  AddFugitiveEmissionRequest.RefrigerantTypeEnum = {
    R401A: 'R401A',
    R401B: 'R401B',
    R401C: 'R401C',
    R402A: 'R402A',
    R402B: 'R402B',
    R403A: 'R403A',
    R403B: 'R403B',
    R404A: 'R404A',
    R405A: 'R405A',
    R406A: 'R406A',
    R407A: 'R407A',
    R407B: 'R407B',
    R407C: 'R407C',
    R407D: 'R407D',
    R407E: 'R407E',
    R407F: 'R407F',
    R408A: 'R408A',
    R409A: 'R409A',
    R409B: 'R409B',
    R410A: 'R410A',
    R410B: 'R410B',
    R411A: 'R411A',
    R411B: 'R411B',
    R412A: 'R412A',
    R413A: 'R413A',
    R414A: 'R414A',
    R414B: 'R414B',
    R415A: 'R415A',
    R415B: 'R415B',
    R416A: 'R416A',
    R417A: 'R417A',
    R417B: 'R417B',
    R417C: 'R417C',
    R418A: 'R418A',
    R419A: 'R419A',
    R419B: 'R419B',
    R420A: 'R420A',
    R421A: 'R421A',
    R421B: 'R421B',
    R422A: 'R422A',
    R422B: 'R422B',
    R422C: 'R422C',
    R422D: 'R422D',
    R422E: 'R422E',
    R423A: 'R423A',
    R424A: 'R424A',
    R425A: 'R425A',
    R426A: 'R426A',
    R427A: 'R427A',
    R428A: 'R428A',
    R429A: 'R429A',
    R430A: 'R430A',
    R431A: 'R431A',
    R432A: 'R432A',
    R433A: 'R433A',
    R433B: 'R433B',
    R433C: 'R433C',
    R434A: 'R434A',
    R435A: 'R435A',
    R436A: 'R436A',
    R436B: 'R436B',
    R437A: 'R437A',
    R438A: 'R438A',
    R439A: 'R439A',
    R440A: 'R440A',
    R441A: 'R441A',
    R442A: 'R442A',
    R443A: 'R443A',
    R444A: 'R444A',
    R445A: 'R445A',
    R500: 'R500',
    R501: 'R501',
    R502: 'R502',
    R503: 'R503',
    R504: 'R504',
    R505: 'R505',
    R506: 'R506',
    R507A: 'R507A',
    R508A: 'R508A',
    R508B: 'R508B',
    R509A: 'R509A',
    R510A: 'R510A',
    R511A: 'R511A',
    R512A: 'R512A',
    CarbonDioxide: 'CARBON_DIOXIDE',
    Methane: 'METHANE',
    NitrousOxide: 'NITROUS_OXIDE',
    Hfc23: 'HFC_23',
    Hfc32: 'HFC_32',
    Hfc41: 'HFC_41',
    Hfc125: 'HFC_125',
    Hfc134: 'HFC_134',
    Hfc134A: 'HFC_134A',
    Hfc143: 'HFC_143',
    Hfc143A: 'HFC_143A',
    Hfc152A: 'HFC_152A',
    Hfc227Ea: 'HFC_227EA',
    Hfc236Fa: 'HFC_236FA',
    Hfc245Fa: 'HFC_245FA',
    Hfc43I0Mee: 'HFC_43_I0MEE',
    PerfluoromethanePfc14: 'PERFLUOROMETHANE_PFC_14',
    PerfluoroethanePfc116: 'PERFLUOROETHANE_PFC_116',
    PerfluoropropanePfc218: 'PERFLUOROPROPANE_PFC_218',
    PerfluorocyclobutanePfc318: 'PERFLUOROCYCLOBUTANE_PFC_318',
    PerfluorobutanePfc3110: 'PERFLUOROBUTANE_PFC_3_1_10',
    PerfluoropentanePfc4112: 'PERFLUOROPENTANE_PFC_4_1_12',
    PerfluorohexanePfc5114: 'PERFLUOROHEXANE_PFC_5_1_14',
    Pfc9118: 'PFC_9_1_18',
    Perfluorocyclopropane: 'PERFLUOROCYCLOPROPANE',
    SulphurHexaflourideSf6: 'SULPHUR_HEXAFLOURIDE_SF6',
    Hfc152: 'HFC_152',
    Hfc161: 'HFC_161',
    Hfc236Cb: 'HFC_236CB',
    Hfc236Ea: 'HFC_236EA',
    Hfc245Ca: 'HFC_245CA',
    Hfc365Mfc: 'HFC_365MFC',
    NitrogenTrifluoride: 'NITROGEN_TRIFLUORIDE',
    Cfc11R11Trichlorofluoromethane: 'CFC_11_R11_TRICHLOROFLUOROMETHANE',
    Cfc12R12Dichlorodifluoromethane: 'CFC_12_R12_DICHLORODIFLUOROMETHANE',
    Cfc13: 'CFC_13',
    Cfc113: 'CFC_113',
    Cfc114: 'CFC_114',
    Cfc115: 'CFC_115',
    Halon1211: 'HALON_1211',
    Halon1301: 'HALON_1301',
    Halon2402: 'HALON_2402',
    CarbonTetrachloride: 'CARBON_TETRACHLORIDE',
    MethylBromide: 'METHYL_BROMIDE',
    MethylChloroform: 'METHYL_CHLOROFORM',
    Hcfc22R22Chlorodifluoromethane: 'HCFC_22_R22_CHLORODIFLUOROMETHANE',
    Hcfc123: 'HCFC_123',
    Hcfc124: 'HCFC_124',
    Hcfc141B: 'HCFC_141B',
    Hcfc142B: 'HCFC_142B',
    Hcfc225Ca: 'HCFC_225CA',
    Hcfc225Cb: 'HCFC_225CB',
    Hcfc21: 'HCFC_21',
    Hfe125: 'HFE_125',
    Hfe134: 'HFE_134',
    Hfe143A: 'HFE_143A',
    Hcfe235Da2: 'HCFE_235DA2',
    Hfe245Cb2: 'HFE_245CB2',
    Hfe245Fa2: 'HFE_245FA2',
    Hfe254Cb2: 'HFE_254CB2',
    Hfe347Mcc3: 'HFE_347MCC3',
    Hfe347Pcf2: 'HFE_347PCF2',
    Hfe356Pcc3: 'HFE_356PCC3',
    Hfe449SlHfe7100: 'HFE_449SL_HFE_7100',
    Hfe569Sf2Hfe7200: 'HFE_569SF2_HFE_7200',
    Hfe4310Pccc124HGalden1040X: 'HFE_43_10PCCC124_H_GALDEN1040X',
    Hfe236Ca12Hg10: 'HFE_236CA12_HG_10',
    Hfe338Pcc13Hg01: 'HFE_338PCC13_HG_01',
    TrifluoromethylSulphurPentafluoride: 'TRIFLUOROMETHYL_SULPHUR_PENTAFLUORIDE',
    Pfpmie: 'PFPMIE',
    Dimethylether: 'DIMETHYLETHER',
    MethyleneChloride: 'METHYLENE_CHLORIDE',
    MethylChloride: 'METHYL_CHLORIDE',
    R290Propane: 'R290_PROPANE',
    R600AIsobutane: 'R600A_ISOBUTANE',
    R600Butane: 'R600_BUTANE',
    R601Pentane: 'R601_PENTANE',
    R601AIsopentane: 'R601A_ISOPENTANE',
    R170Ethane: 'R170_ETHANE',
    R1270Propene: 'R1270_PROPENE',
    R1234Yf: 'R1234YF',
    R1234Ze: 'R1234ZE'
  };
})(AddFugitiveEmissionRequest || (AddFugitiveEmissionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddHeatConsumptionRequest;
(function (AddHeatConsumptionRequest) {
  AddHeatConsumptionRequest.CountryEnum = {
    Pl: 'PL',
    De: 'DE'
  };
})(AddHeatConsumptionRequest || (AddHeatConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddManualCompaniesCO2ScoreRequest;
(function (AddManualCompaniesCO2ScoreRequest) {
  AddManualCompaniesCO2ScoreRequest.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(AddManualCompaniesCO2ScoreRequest || (AddManualCompaniesCO2ScoreRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddManufacturedBottlesRequest;
(function (AddManufacturedBottlesRequest) {
  AddManufacturedBottlesRequest.BottleVolumeEnum = {
    NoRush533Ml: 'NO_RUSH_533ML',
    IsotonicRush750Ml: 'ISOTONIC_RUSH_750ML',
    PowerBooster1000Ml: 'POWER_BOOSTER_1000ML',
    EnergyBlast500Ml: 'ENERGY_BLAST_500ML',
    LightningBolt250Ml: 'LIGHTNING_BOLT_250ML',
    SuperCharge1500Ml: 'SUPER_CHARGE_1500ML',
    MegaBoost1750Ml: 'MEGA_BOOST_1750ML',
    UltraPower2000Ml: 'ULTRA_POWER_2000ML',
    ExtremeEnergy5000Ml: 'EXTREME_ENERGY_5000ML'
  };
})(AddManufacturedBottlesRequest || (AddManufacturedBottlesRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddRemoteWorkRequest;
(function (AddRemoteWorkRequest) {
  AddRemoteWorkRequest.RemoteWorkEnum = {
    PartTime14: 'PART_TIME_1_4',
    PartTime12: 'PART_TIME_1_2',
    FullTime: 'FULL_TIME',
    OneAndAHalfTime: 'ONE_AND_A_HALF_TIME'
  };
})(AddRemoteWorkRequest || (AddRemoteWorkRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddRenewableEnergyConsumptionRequest;
(function (AddRenewableEnergyConsumptionRequest) {
  AddRenewableEnergyConsumptionRequest.InstallationTypeEnum = {
    Photovoltaic: 'PHOTOVOLTAIC',
    Hydroelectric: 'HYDROELECTRIC',
    WindTurbine: 'WIND_TURBINE',
    OtherInstallation: 'OTHER_INSTALLATION'
  };
  AddRenewableEnergyConsumptionRequest.RenewableEnergyConsumedUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(AddRenewableEnergyConsumptionRequest || (AddRenewableEnergyConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddStationaryDeviceRequest;
(function (AddStationaryDeviceRequest) {
  AddStationaryDeviceRequest.HeatingDeviceTypeEnum = {
    Boiler: 'BOILER',
    Generator: 'GENERATOR',
    Pump: 'PUMP',
    CombustionTurbine: 'COMBUSTION_TURBINE',
    Compressor: 'COMPRESSOR',
    Dryer: 'DRYER',
    Fireplace: 'FIREPLACE',
    Torch: 'TORCH',
    FreeStandingHeater: 'FREE_STANDING_HEATER',
    TileStove: 'TILE_STOVE',
    Heater: 'HEATER',
    Incinerator: 'INCINERATOR',
    Oven: 'OVEN',
    BakingOven: 'BAKING_OVEN',
    ProcessHeaters: 'PROCESS_HEATERS',
    ThermalOxidizer: 'THERMAL_OXIDIZER'
  };
  AddStationaryDeviceRequest.FuelEnum = {
    Bioethanol: 'BIOETHANOL',
    Biodiesel: 'BIODIESEL',
    BiomethaneCompressed: 'BIOMETHANE_COMPRESSED',
    Biopropane: 'BIOPROPANE',
    Biopetrol: 'BIOPETROL',
    RenewablePetrol: 'RENEWABLE_PETROL',
    BiomethaneLiquefied: 'BIOMETHANE_LIQUEFIED',
    Biomethanol: 'BIOMETHANOL',
    BiomassWoodLogs: 'BIOMASS_WOOD_LOGS',
    BiomassWoodPellets: 'BIOMASS_WOOD_PELLETS',
    BiomassWoodChips: 'BIOMASS_WOOD_CHIPS',
    BiomassGrassStraw: 'BIOMASS_GRASS_STRAW',
    Biogas: 'BIOGAS',
    LandfillGas: 'LANDFILL_GAS',
    Coal: 'COAL',
    BurningOil: 'BURNING_OIL',
    Lng: 'LNG',
    Cng: 'CNG',
    Diesel: 'DIESEL',
    Petrol: 'PETROL'
  };
  AddStationaryDeviceRequest.FuelUnitEnum = {
    Litres: 'LITRES',
    Gj: 'GJ',
    Kwh: 'KWH',
    Tonnes: 'TONNES',
    Kg: 'KG'
  };
})(AddStationaryDeviceRequest || (AddStationaryDeviceRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AddVehicleRequest;
(function (AddVehicleRequest) {
  AddVehicleRequest.FuelEnum = {
    Lpg: 'LPG',
    Petrol: 'PETROL',
    Diesel: 'DIESEL',
    Cng: 'CNG',
    Lng: 'LNG',
    Biodiesel: 'BIODIESEL',
    Bioethanol: 'BIOETHANOL'
  };
  AddVehicleRequest.VehicleCategoryEnum = {
    PassengerCar: 'PASSENGER_CAR',
    Truck: 'TRUCK',
    DeliveryVehicle: 'DELIVERY_VEHICLE',
    Motorcycle: 'MOTORCYCLE',
    Tractor: 'TRACTOR',
    Forklift: 'FORKLIFT',
    Lift: 'LIFT',
    Motorboat: 'MOTORBOAT'
  };
  AddVehicleRequest.FuelUnitEnum = {
    Litres: 'LITRES',
    Tones: 'TONES',
    KwhNetto: 'KWH_NETTO',
    KwhBrutto: 'KWH_BRUTTO'
  };
})(AddVehicleRequest || (AddVehicleRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var ApiError;
(function (ApiError) {
  ApiError.StatusEnum = {
    _100Continue: '100 CONTINUE',
    _101SwitchingProtocols: '101 SWITCHING_PROTOCOLS',
    _102Processing: '102 PROCESSING',
    _103EarlyHints: '103 EARLY_HINTS',
    _103Checkpoint: '103 CHECKPOINT',
    _200Ok: '200 OK',
    _201Created: '201 CREATED',
    _202Accepted: '202 ACCEPTED',
    _203NonAuthoritativeInformation: '203 NON_AUTHORITATIVE_INFORMATION',
    _204NoContent: '204 NO_CONTENT',
    _205ResetContent: '205 RESET_CONTENT',
    _206PartialContent: '206 PARTIAL_CONTENT',
    _207MultiStatus: '207 MULTI_STATUS',
    _208AlreadyReported: '208 ALREADY_REPORTED',
    _226ImUsed: '226 IM_USED',
    _300MultipleChoices: '300 MULTIPLE_CHOICES',
    _301MovedPermanently: '301 MOVED_PERMANENTLY',
    _302Found: '302 FOUND',
    _302MovedTemporarily: '302 MOVED_TEMPORARILY',
    _303SeeOther: '303 SEE_OTHER',
    _304NotModified: '304 NOT_MODIFIED',
    _305UseProxy: '305 USE_PROXY',
    _307TemporaryRedirect: '307 TEMPORARY_REDIRECT',
    _308PermanentRedirect: '308 PERMANENT_REDIRECT',
    _400BadRequest: '400 BAD_REQUEST',
    _401Unauthorized: '401 UNAUTHORIZED',
    _402PaymentRequired: '402 PAYMENT_REQUIRED',
    _403Forbidden: '403 FORBIDDEN',
    _404NotFound: '404 NOT_FOUND',
    _405MethodNotAllowed: '405 METHOD_NOT_ALLOWED',
    _406NotAcceptable: '406 NOT_ACCEPTABLE',
    _407ProxyAuthenticationRequired: '407 PROXY_AUTHENTICATION_REQUIRED',
    _408RequestTimeout: '408 REQUEST_TIMEOUT',
    _409Conflict: '409 CONFLICT',
    _410Gone: '410 GONE',
    _411LengthRequired: '411 LENGTH_REQUIRED',
    _412PreconditionFailed: '412 PRECONDITION_FAILED',
    _413PayloadTooLarge: '413 PAYLOAD_TOO_LARGE',
    _413RequestEntityTooLarge: '413 REQUEST_ENTITY_TOO_LARGE',
    _414UriTooLong: '414 URI_TOO_LONG',
    _414RequestUriTooLong: '414 REQUEST_URI_TOO_LONG',
    _415UnsupportedMediaType: '415 UNSUPPORTED_MEDIA_TYPE',
    _416RequestedRangeNotSatisfiable: '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    _417ExpectationFailed: '417 EXPECTATION_FAILED',
    _418IAmATeapot: '418 I_AM_A_TEAPOT',
    _419InsufficientSpaceOnResource: '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    _420MethodFailure: '420 METHOD_FAILURE',
    _421DestinationLocked: '421 DESTINATION_LOCKED',
    _422UnprocessableEntity: '422 UNPROCESSABLE_ENTITY',
    _423Locked: '423 LOCKED',
    _424FailedDependency: '424 FAILED_DEPENDENCY',
    _425TooEarly: '425 TOO_EARLY',
    _426UpgradeRequired: '426 UPGRADE_REQUIRED',
    _428PreconditionRequired: '428 PRECONDITION_REQUIRED',
    _429TooManyRequests: '429 TOO_MANY_REQUESTS',
    _431RequestHeaderFieldsTooLarge: '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    _451UnavailableForLegalReasons: '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    _500InternalServerError: '500 INTERNAL_SERVER_ERROR',
    _501NotImplemented: '501 NOT_IMPLEMENTED',
    _502BadGateway: '502 BAD_GATEWAY',
    _503ServiceUnavailable: '503 SERVICE_UNAVAILABLE',
    _504GatewayTimeout: '504 GATEWAY_TIMEOUT',
    _505HttpVersionNotSupported: '505 HTTP_VERSION_NOT_SUPPORTED',
    _506VariantAlsoNegotiates: '506 VARIANT_ALSO_NEGOTIATES',
    _507InsufficientStorage: '507 INSUFFICIENT_STORAGE',
    _508LoopDetected: '508 LOOP_DETECTED',
    _509BandwidthLimitExceeded: '509 BANDWIDTH_LIMIT_EXCEEDED',
    _510NotExtended: '510 NOT_EXTENDED',
    _511NetworkAuthenticationRequired: '511 NETWORK_AUTHENTICATION_REQUIRED'
  };
})(ApiError || (ApiError = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var CertificatedRenewableEnergyBoughtEntryDto;
(function (CertificatedRenewableEnergyBoughtEntryDto) {
  CertificatedRenewableEnergyBoughtEntryDto.CertificatedRenewableEnergyBoughtUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(CertificatedRenewableEnergyBoughtEntryDto || (CertificatedRenewableEnergyBoughtEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var CurrentAndOzeRatioSummary;
(function (CurrentAndOzeRatioSummary) {
  CurrentAndOzeRatioSummary.CurrentUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(CurrentAndOzeRatioSummary || (CurrentAndOzeRatioSummary = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ElectricityConsumptionEntryDto;
(function (ElectricityConsumptionEntryDto) {
  ElectricityConsumptionEntryDto.EnergySupplierEnum = {
    Enea: 'ENEA',
    Energa: 'ENERGA',
    Eon: 'EON',
    Tauron: 'TAURON',
    Pge: 'PGE',
    Unknown: 'UNKNOWN'
  };
})(ElectricityConsumptionEntryDto || (ElectricityConsumptionEntryDto = {}));
var EsgConference;
(function (EsgConference) {
  EsgConference.StatusEnum = {
    Created: 'CREATED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Finished: 'FINISHED',
    Archived: 'ARCHIVED'
  };
})(EsgConference || (EsgConference = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var EsgConferenceEntry;
(function (EsgConferenceEntry) {
  EsgConferenceEntry.VehicleTypeEnum = {
    WalkOrBike: 'WALK_OR_BIKE',
    Scooter: 'SCOOTER',
    Bus: 'BUS',
    Tram: 'TRAM',
    Metro: 'METRO',
    Car: 'CAR',
    Plane: 'PLANE',
    Ship: 'SHIP'
  };
  EsgConferenceEntry.FuelTypeEnum = {
    Lpg: 'LPG',
    Petrol: 'PETROL',
    Diesel: 'DIESEL',
    Cng: 'CNG',
    Lng: 'LNG',
    Biodiesel: 'BIODIESEL',
    Bioethanol: 'BIOETHANOL'
  };
})(EsgConferenceEntry || (EsgConferenceEntry = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var FugitiveEmissionEntryDto;
(function (FugitiveEmissionEntryDto) {
  FugitiveEmissionEntryDto.CoolingDeviceTypeEnum = {
    VehicleAirConditioner: 'VEHICLE_AIR_CONDITIONER',
    RefrigerationDevice: 'REFRIGERATION_DEVICE',
    StationaryAirConditioner: 'STATIONARY_AIR_CONDITIONER',
    IndustrialProcessCooling: 'INDUSTRIAL_PROCESS_COOLING',
    RefrigeratedTransport: 'REFRIGERATED_TRANSPORT',
    RefrigeratedWarehouses: 'REFRIGERATED_WAREHOUSES'
  };
  FugitiveEmissionEntryDto.RefrigerantTypeEnum = {
    R401A: 'R401A',
    R401B: 'R401B',
    R401C: 'R401C',
    R402A: 'R402A',
    R402B: 'R402B',
    R403A: 'R403A',
    R403B: 'R403B',
    R404A: 'R404A',
    R405A: 'R405A',
    R406A: 'R406A',
    R407A: 'R407A',
    R407B: 'R407B',
    R407C: 'R407C',
    R407D: 'R407D',
    R407E: 'R407E',
    R407F: 'R407F',
    R408A: 'R408A',
    R409A: 'R409A',
    R409B: 'R409B',
    R410A: 'R410A',
    R410B: 'R410B',
    R411A: 'R411A',
    R411B: 'R411B',
    R412A: 'R412A',
    R413A: 'R413A',
    R414A: 'R414A',
    R414B: 'R414B',
    R415A: 'R415A',
    R415B: 'R415B',
    R416A: 'R416A',
    R417A: 'R417A',
    R417B: 'R417B',
    R417C: 'R417C',
    R418A: 'R418A',
    R419A: 'R419A',
    R419B: 'R419B',
    R420A: 'R420A',
    R421A: 'R421A',
    R421B: 'R421B',
    R422A: 'R422A',
    R422B: 'R422B',
    R422C: 'R422C',
    R422D: 'R422D',
    R422E: 'R422E',
    R423A: 'R423A',
    R424A: 'R424A',
    R425A: 'R425A',
    R426A: 'R426A',
    R427A: 'R427A',
    R428A: 'R428A',
    R429A: 'R429A',
    R430A: 'R430A',
    R431A: 'R431A',
    R432A: 'R432A',
    R433A: 'R433A',
    R433B: 'R433B',
    R433C: 'R433C',
    R434A: 'R434A',
    R435A: 'R435A',
    R436A: 'R436A',
    R436B: 'R436B',
    R437A: 'R437A',
    R438A: 'R438A',
    R439A: 'R439A',
    R440A: 'R440A',
    R441A: 'R441A',
    R442A: 'R442A',
    R443A: 'R443A',
    R444A: 'R444A',
    R445A: 'R445A',
    R500: 'R500',
    R501: 'R501',
    R502: 'R502',
    R503: 'R503',
    R504: 'R504',
    R505: 'R505',
    R506: 'R506',
    R507A: 'R507A',
    R508A: 'R508A',
    R508B: 'R508B',
    R509A: 'R509A',
    R510A: 'R510A',
    R511A: 'R511A',
    R512A: 'R512A',
    CarbonDioxide: 'CARBON_DIOXIDE',
    Methane: 'METHANE',
    NitrousOxide: 'NITROUS_OXIDE',
    Hfc23: 'HFC_23',
    Hfc32: 'HFC_32',
    Hfc41: 'HFC_41',
    Hfc125: 'HFC_125',
    Hfc134: 'HFC_134',
    Hfc134A: 'HFC_134A',
    Hfc143: 'HFC_143',
    Hfc143A: 'HFC_143A',
    Hfc152A: 'HFC_152A',
    Hfc227Ea: 'HFC_227EA',
    Hfc236Fa: 'HFC_236FA',
    Hfc245Fa: 'HFC_245FA',
    Hfc43I0Mee: 'HFC_43_I0MEE',
    PerfluoromethanePfc14: 'PERFLUOROMETHANE_PFC_14',
    PerfluoroethanePfc116: 'PERFLUOROETHANE_PFC_116',
    PerfluoropropanePfc218: 'PERFLUOROPROPANE_PFC_218',
    PerfluorocyclobutanePfc318: 'PERFLUOROCYCLOBUTANE_PFC_318',
    PerfluorobutanePfc3110: 'PERFLUOROBUTANE_PFC_3_1_10',
    PerfluoropentanePfc4112: 'PERFLUOROPENTANE_PFC_4_1_12',
    PerfluorohexanePfc5114: 'PERFLUOROHEXANE_PFC_5_1_14',
    Pfc9118: 'PFC_9_1_18',
    Perfluorocyclopropane: 'PERFLUOROCYCLOPROPANE',
    SulphurHexaflourideSf6: 'SULPHUR_HEXAFLOURIDE_SF6',
    Hfc152: 'HFC_152',
    Hfc161: 'HFC_161',
    Hfc236Cb: 'HFC_236CB',
    Hfc236Ea: 'HFC_236EA',
    Hfc245Ca: 'HFC_245CA',
    Hfc365Mfc: 'HFC_365MFC',
    NitrogenTrifluoride: 'NITROGEN_TRIFLUORIDE',
    Cfc11R11Trichlorofluoromethane: 'CFC_11_R11_TRICHLOROFLUOROMETHANE',
    Cfc12R12Dichlorodifluoromethane: 'CFC_12_R12_DICHLORODIFLUOROMETHANE',
    Cfc13: 'CFC_13',
    Cfc113: 'CFC_113',
    Cfc114: 'CFC_114',
    Cfc115: 'CFC_115',
    Halon1211: 'HALON_1211',
    Halon1301: 'HALON_1301',
    Halon2402: 'HALON_2402',
    CarbonTetrachloride: 'CARBON_TETRACHLORIDE',
    MethylBromide: 'METHYL_BROMIDE',
    MethylChloroform: 'METHYL_CHLOROFORM',
    Hcfc22R22Chlorodifluoromethane: 'HCFC_22_R22_CHLORODIFLUOROMETHANE',
    Hcfc123: 'HCFC_123',
    Hcfc124: 'HCFC_124',
    Hcfc141B: 'HCFC_141B',
    Hcfc142B: 'HCFC_142B',
    Hcfc225Ca: 'HCFC_225CA',
    Hcfc225Cb: 'HCFC_225CB',
    Hcfc21: 'HCFC_21',
    Hfe125: 'HFE_125',
    Hfe134: 'HFE_134',
    Hfe143A: 'HFE_143A',
    Hcfe235Da2: 'HCFE_235DA2',
    Hfe245Cb2: 'HFE_245CB2',
    Hfe245Fa2: 'HFE_245FA2',
    Hfe254Cb2: 'HFE_254CB2',
    Hfe347Mcc3: 'HFE_347MCC3',
    Hfe347Pcf2: 'HFE_347PCF2',
    Hfe356Pcc3: 'HFE_356PCC3',
    Hfe449SlHfe7100: 'HFE_449SL_HFE_7100',
    Hfe569Sf2Hfe7200: 'HFE_569SF2_HFE_7200',
    Hfe4310Pccc124HGalden1040X: 'HFE_43_10PCCC124_H_GALDEN1040X',
    Hfe236Ca12Hg10: 'HFE_236CA12_HG_10',
    Hfe338Pcc13Hg01: 'HFE_338PCC13_HG_01',
    TrifluoromethylSulphurPentafluoride: 'TRIFLUOROMETHYL_SULPHUR_PENTAFLUORIDE',
    Pfpmie: 'PFPMIE',
    Dimethylether: 'DIMETHYLETHER',
    MethyleneChloride: 'METHYLENE_CHLORIDE',
    MethylChloride: 'METHYL_CHLORIDE',
    R290Propane: 'R290_PROPANE',
    R600AIsobutane: 'R600A_ISOBUTANE',
    R600Butane: 'R600_BUTANE',
    R601Pentane: 'R601_PENTANE',
    R601AIsopentane: 'R601A_ISOPENTANE',
    R170Ethane: 'R170_ETHANE',
    R1270Propene: 'R1270_PROPENE',
    R1234Yf: 'R1234YF',
    R1234Ze: 'R1234ZE'
  };
})(FugitiveEmissionEntryDto || (FugitiveEmissionEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var GetSummaryAllScopesRequest;
(function (GetSummaryAllScopesRequest) {
  GetSummaryAllScopesRequest.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(GetSummaryAllScopesRequest || (GetSummaryAllScopesRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var GetSummaryFirstScopeRequest;
(function (GetSummaryFirstScopeRequest) {
  GetSummaryFirstScopeRequest.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(GetSummaryFirstScopeRequest || (GetSummaryFirstScopeRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var GetSummarySecondScopeRequest;
(function (GetSummarySecondScopeRequest) {
  GetSummarySecondScopeRequest.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(GetSummarySecondScopeRequest || (GetSummarySecondScopeRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var GetSummaryThirdScopeRequest;
(function (GetSummaryThirdScopeRequest) {
  GetSummaryThirdScopeRequest.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(GetSummaryThirdScopeRequest || (GetSummaryThirdScopeRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var HeatConsumptionEntryDto;
(function (HeatConsumptionEntryDto) {
  HeatConsumptionEntryDto.CountryEnum = {
    Pl: 'PL',
    De: 'DE'
  };
})(HeatConsumptionEntryDto || (HeatConsumptionEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ManualCompaniesCO2ScoreEntryResponse;
(function (ManualCompaniesCO2ScoreEntryResponse) {
  ManualCompaniesCO2ScoreEntryResponse.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(ManualCompaniesCO2ScoreEntryResponse || (ManualCompaniesCO2ScoreEntryResponse = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ManufacturedBottlesEntryDto;
(function (ManufacturedBottlesEntryDto) {
  ManufacturedBottlesEntryDto.BottleVolumeEnum = {
    NoRush533Ml: 'NO_RUSH_533ML',
    IsotonicRush750Ml: 'ISOTONIC_RUSH_750ML',
    PowerBooster1000Ml: 'POWER_BOOSTER_1000ML',
    EnergyBlast500Ml: 'ENERGY_BLAST_500ML',
    LightningBolt250Ml: 'LIGHTNING_BOLT_250ML',
    SuperCharge1500Ml: 'SUPER_CHARGE_1500ML',
    MegaBoost1750Ml: 'MEGA_BOOST_1750ML',
    UltraPower2000Ml: 'ULTRA_POWER_2000ML',
    ExtremeEnergy5000Ml: 'EXTREME_ENERGY_5000ML'
  };
})(ManufacturedBottlesEntryDto || (ManufacturedBottlesEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchElectricityConsumptionRequest;
(function (PatchElectricityConsumptionRequest) {
  PatchElectricityConsumptionRequest.EnergySupplierEnum = {
    Enea: 'ENEA',
    Energa: 'ENERGA',
    Eon: 'EON',
    Tauron: 'TAURON',
    Pge: 'PGE',
    Unknown: 'UNKNOWN'
  };
})(PatchElectricityConsumptionRequest || (PatchElectricityConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchFugitiveEmissionRequest;
(function (PatchFugitiveEmissionRequest) {
  PatchFugitiveEmissionRequest.CoolingDeviceTypeEnum = {
    VehicleAirConditioner: 'VEHICLE_AIR_CONDITIONER',
    RefrigerationDevice: 'REFRIGERATION_DEVICE',
    StationaryAirConditioner: 'STATIONARY_AIR_CONDITIONER',
    IndustrialProcessCooling: 'INDUSTRIAL_PROCESS_COOLING',
    RefrigeratedTransport: 'REFRIGERATED_TRANSPORT',
    RefrigeratedWarehouses: 'REFRIGERATED_WAREHOUSES'
  };
  PatchFugitiveEmissionRequest.RefrigerantTypeEnum = {
    R401A: 'R401A',
    R401B: 'R401B',
    R401C: 'R401C',
    R402A: 'R402A',
    R402B: 'R402B',
    R403A: 'R403A',
    R403B: 'R403B',
    R404A: 'R404A',
    R405A: 'R405A',
    R406A: 'R406A',
    R407A: 'R407A',
    R407B: 'R407B',
    R407C: 'R407C',
    R407D: 'R407D',
    R407E: 'R407E',
    R407F: 'R407F',
    R408A: 'R408A',
    R409A: 'R409A',
    R409B: 'R409B',
    R410A: 'R410A',
    R410B: 'R410B',
    R411A: 'R411A',
    R411B: 'R411B',
    R412A: 'R412A',
    R413A: 'R413A',
    R414A: 'R414A',
    R414B: 'R414B',
    R415A: 'R415A',
    R415B: 'R415B',
    R416A: 'R416A',
    R417A: 'R417A',
    R417B: 'R417B',
    R417C: 'R417C',
    R418A: 'R418A',
    R419A: 'R419A',
    R419B: 'R419B',
    R420A: 'R420A',
    R421A: 'R421A',
    R421B: 'R421B',
    R422A: 'R422A',
    R422B: 'R422B',
    R422C: 'R422C',
    R422D: 'R422D',
    R422E: 'R422E',
    R423A: 'R423A',
    R424A: 'R424A',
    R425A: 'R425A',
    R426A: 'R426A',
    R427A: 'R427A',
    R428A: 'R428A',
    R429A: 'R429A',
    R430A: 'R430A',
    R431A: 'R431A',
    R432A: 'R432A',
    R433A: 'R433A',
    R433B: 'R433B',
    R433C: 'R433C',
    R434A: 'R434A',
    R435A: 'R435A',
    R436A: 'R436A',
    R436B: 'R436B',
    R437A: 'R437A',
    R438A: 'R438A',
    R439A: 'R439A',
    R440A: 'R440A',
    R441A: 'R441A',
    R442A: 'R442A',
    R443A: 'R443A',
    R444A: 'R444A',
    R445A: 'R445A',
    R500: 'R500',
    R501: 'R501',
    R502: 'R502',
    R503: 'R503',
    R504: 'R504',
    R505: 'R505',
    R506: 'R506',
    R507A: 'R507A',
    R508A: 'R508A',
    R508B: 'R508B',
    R509A: 'R509A',
    R510A: 'R510A',
    R511A: 'R511A',
    R512A: 'R512A',
    CarbonDioxide: 'CARBON_DIOXIDE',
    Methane: 'METHANE',
    NitrousOxide: 'NITROUS_OXIDE',
    Hfc23: 'HFC_23',
    Hfc32: 'HFC_32',
    Hfc41: 'HFC_41',
    Hfc125: 'HFC_125',
    Hfc134: 'HFC_134',
    Hfc134A: 'HFC_134A',
    Hfc143: 'HFC_143',
    Hfc143A: 'HFC_143A',
    Hfc152A: 'HFC_152A',
    Hfc227Ea: 'HFC_227EA',
    Hfc236Fa: 'HFC_236FA',
    Hfc245Fa: 'HFC_245FA',
    Hfc43I0Mee: 'HFC_43_I0MEE',
    PerfluoromethanePfc14: 'PERFLUOROMETHANE_PFC_14',
    PerfluoroethanePfc116: 'PERFLUOROETHANE_PFC_116',
    PerfluoropropanePfc218: 'PERFLUOROPROPANE_PFC_218',
    PerfluorocyclobutanePfc318: 'PERFLUOROCYCLOBUTANE_PFC_318',
    PerfluorobutanePfc3110: 'PERFLUOROBUTANE_PFC_3_1_10',
    PerfluoropentanePfc4112: 'PERFLUOROPENTANE_PFC_4_1_12',
    PerfluorohexanePfc5114: 'PERFLUOROHEXANE_PFC_5_1_14',
    Pfc9118: 'PFC_9_1_18',
    Perfluorocyclopropane: 'PERFLUOROCYCLOPROPANE',
    SulphurHexaflourideSf6: 'SULPHUR_HEXAFLOURIDE_SF6',
    Hfc152: 'HFC_152',
    Hfc161: 'HFC_161',
    Hfc236Cb: 'HFC_236CB',
    Hfc236Ea: 'HFC_236EA',
    Hfc245Ca: 'HFC_245CA',
    Hfc365Mfc: 'HFC_365MFC',
    NitrogenTrifluoride: 'NITROGEN_TRIFLUORIDE',
    Cfc11R11Trichlorofluoromethane: 'CFC_11_R11_TRICHLOROFLUOROMETHANE',
    Cfc12R12Dichlorodifluoromethane: 'CFC_12_R12_DICHLORODIFLUOROMETHANE',
    Cfc13: 'CFC_13',
    Cfc113: 'CFC_113',
    Cfc114: 'CFC_114',
    Cfc115: 'CFC_115',
    Halon1211: 'HALON_1211',
    Halon1301: 'HALON_1301',
    Halon2402: 'HALON_2402',
    CarbonTetrachloride: 'CARBON_TETRACHLORIDE',
    MethylBromide: 'METHYL_BROMIDE',
    MethylChloroform: 'METHYL_CHLOROFORM',
    Hcfc22R22Chlorodifluoromethane: 'HCFC_22_R22_CHLORODIFLUOROMETHANE',
    Hcfc123: 'HCFC_123',
    Hcfc124: 'HCFC_124',
    Hcfc141B: 'HCFC_141B',
    Hcfc142B: 'HCFC_142B',
    Hcfc225Ca: 'HCFC_225CA',
    Hcfc225Cb: 'HCFC_225CB',
    Hcfc21: 'HCFC_21',
    Hfe125: 'HFE_125',
    Hfe134: 'HFE_134',
    Hfe143A: 'HFE_143A',
    Hcfe235Da2: 'HCFE_235DA2',
    Hfe245Cb2: 'HFE_245CB2',
    Hfe245Fa2: 'HFE_245FA2',
    Hfe254Cb2: 'HFE_254CB2',
    Hfe347Mcc3: 'HFE_347MCC3',
    Hfe347Pcf2: 'HFE_347PCF2',
    Hfe356Pcc3: 'HFE_356PCC3',
    Hfe449SlHfe7100: 'HFE_449SL_HFE_7100',
    Hfe569Sf2Hfe7200: 'HFE_569SF2_HFE_7200',
    Hfe4310Pccc124HGalden1040X: 'HFE_43_10PCCC124_H_GALDEN1040X',
    Hfe236Ca12Hg10: 'HFE_236CA12_HG_10',
    Hfe338Pcc13Hg01: 'HFE_338PCC13_HG_01',
    TrifluoromethylSulphurPentafluoride: 'TRIFLUOROMETHYL_SULPHUR_PENTAFLUORIDE',
    Pfpmie: 'PFPMIE',
    Dimethylether: 'DIMETHYLETHER',
    MethyleneChloride: 'METHYLENE_CHLORIDE',
    MethylChloride: 'METHYL_CHLORIDE',
    R290Propane: 'R290_PROPANE',
    R600AIsobutane: 'R600A_ISOBUTANE',
    R600Butane: 'R600_BUTANE',
    R601Pentane: 'R601_PENTANE',
    R601AIsopentane: 'R601A_ISOPENTANE',
    R170Ethane: 'R170_ETHANE',
    R1270Propene: 'R1270_PROPENE',
    R1234Yf: 'R1234YF',
    R1234Ze: 'R1234ZE'
  };
})(PatchFugitiveEmissionRequest || (PatchFugitiveEmissionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchHeatConsumptionRequest;
(function (PatchHeatConsumptionRequest) {
  PatchHeatConsumptionRequest.CountryEnum = {
    Pl: 'PL',
    De: 'DE'
  };
})(PatchHeatConsumptionRequest || (PatchHeatConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchManufacturedBottlesRequest;
(function (PatchManufacturedBottlesRequest) {
  PatchManufacturedBottlesRequest.BottleVolumeEnum = {
    NoRush533Ml: 'NO_RUSH_533ML',
    IsotonicRush750Ml: 'ISOTONIC_RUSH_750ML',
    PowerBooster1000Ml: 'POWER_BOOSTER_1000ML',
    EnergyBlast500Ml: 'ENERGY_BLAST_500ML',
    LightningBolt250Ml: 'LIGHTNING_BOLT_250ML',
    SuperCharge1500Ml: 'SUPER_CHARGE_1500ML',
    MegaBoost1750Ml: 'MEGA_BOOST_1750ML',
    UltraPower2000Ml: 'ULTRA_POWER_2000ML',
    ExtremeEnergy5000Ml: 'EXTREME_ENERGY_5000ML'
  };
})(PatchManufacturedBottlesRequest || (PatchManufacturedBottlesRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchRemoteWorkRequest;
(function (PatchRemoteWorkRequest) {
  PatchRemoteWorkRequest.RemoteWorkEnum = {
    PartTime14: 'PART_TIME_1_4',
    PartTime12: 'PART_TIME_1_2',
    FullTime: 'FULL_TIME',
    OneAndAHalfTime: 'ONE_AND_A_HALF_TIME'
  };
})(PatchRemoteWorkRequest || (PatchRemoteWorkRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchRenewableEnergyConsumptionRequest;
(function (PatchRenewableEnergyConsumptionRequest) {
  PatchRenewableEnergyConsumptionRequest.InstallationTypeEnum = {
    Photovoltaic: 'PHOTOVOLTAIC',
    Hydroelectric: 'HYDROELECTRIC',
    WindTurbine: 'WIND_TURBINE',
    OtherInstallation: 'OTHER_INSTALLATION'
  };
})(PatchRenewableEnergyConsumptionRequest || (PatchRenewableEnergyConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchStationaryDeviceRequest;
(function (PatchStationaryDeviceRequest) {
  PatchStationaryDeviceRequest.HeatingDeviceTypeEnum = {
    Boiler: 'BOILER',
    Generator: 'GENERATOR',
    Pump: 'PUMP',
    CombustionTurbine: 'COMBUSTION_TURBINE',
    Compressor: 'COMPRESSOR',
    Dryer: 'DRYER',
    Fireplace: 'FIREPLACE',
    Torch: 'TORCH',
    FreeStandingHeater: 'FREE_STANDING_HEATER',
    TileStove: 'TILE_STOVE',
    Heater: 'HEATER',
    Incinerator: 'INCINERATOR',
    Oven: 'OVEN',
    BakingOven: 'BAKING_OVEN',
    ProcessHeaters: 'PROCESS_HEATERS',
    ThermalOxidizer: 'THERMAL_OXIDIZER'
  };
  PatchStationaryDeviceRequest.FuelEnum = {
    Bioethanol: 'BIOETHANOL',
    Biodiesel: 'BIODIESEL',
    BiomethaneCompressed: 'BIOMETHANE_COMPRESSED',
    Biopropane: 'BIOPROPANE',
    Biopetrol: 'BIOPETROL',
    RenewablePetrol: 'RENEWABLE_PETROL',
    BiomethaneLiquefied: 'BIOMETHANE_LIQUEFIED',
    Biomethanol: 'BIOMETHANOL',
    BiomassWoodLogs: 'BIOMASS_WOOD_LOGS',
    BiomassWoodPellets: 'BIOMASS_WOOD_PELLETS',
    BiomassWoodChips: 'BIOMASS_WOOD_CHIPS',
    BiomassGrassStraw: 'BIOMASS_GRASS_STRAW',
    Biogas: 'BIOGAS',
    LandfillGas: 'LANDFILL_GAS',
    Coal: 'COAL',
    BurningOil: 'BURNING_OIL',
    Lng: 'LNG',
    Cng: 'CNG',
    Diesel: 'DIESEL',
    Petrol: 'PETROL'
  };
  PatchStationaryDeviceRequest.FuelUnitEnum = {
    Litres: 'LITRES',
    Gj: 'GJ',
    Kwh: 'KWH',
    Tonnes: 'TONNES',
    Kg: 'KG'
  };
})(PatchStationaryDeviceRequest || (PatchStationaryDeviceRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PatchVehicleRequest;
(function (PatchVehicleRequest) {
  PatchVehicleRequest.FuelEnum = {
    Lpg: 'LPG',
    Petrol: 'PETROL',
    Diesel: 'DIESEL',
    Cng: 'CNG',
    Lng: 'LNG',
    Biodiesel: 'BIODIESEL',
    Bioethanol: 'BIOETHANOL'
  };
  PatchVehicleRequest.FuelUnitEnum = {
    Litres: 'LITRES',
    Tones: 'TONES',
    KwhNetto: 'KWH_NETTO',
    KwhBrutto: 'KWH_BRUTTO'
  };
})(PatchVehicleRequest || (PatchVehicleRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var RemoteWorkEntryDto;
(function (RemoteWorkEntryDto) {
  RemoteWorkEntryDto.RemoteWorkEnum = {
    PartTime14: 'PART_TIME_1_4',
    PartTime12: 'PART_TIME_1_2',
    FullTime: 'FULL_TIME',
    OneAndAHalfTime: 'ONE_AND_A_HALF_TIME'
  };
})(RemoteWorkEntryDto || (RemoteWorkEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var RenewableEnergyConsumptionEntryDto;
(function (RenewableEnergyConsumptionEntryDto) {
  RenewableEnergyConsumptionEntryDto.InstallationTypeEnum = {
    Photovoltaic: 'PHOTOVOLTAIC',
    Hydroelectric: 'HYDROELECTRIC',
    WindTurbine: 'WIND_TURBINE',
    OtherInstallation: 'OTHER_INSTALLATION'
  };
  RenewableEnergyConsumptionEntryDto.RenewableEnergyConsumedUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(RenewableEnergyConsumptionEntryDto || (RenewableEnergyConsumptionEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var StationaryDeviceEntryDto;
(function (StationaryDeviceEntryDto) {
  StationaryDeviceEntryDto.HeatingDevicesTypeEnum = {
    Boiler: 'BOILER',
    Generator: 'GENERATOR',
    Pump: 'PUMP',
    CombustionTurbine: 'COMBUSTION_TURBINE',
    Compressor: 'COMPRESSOR',
    Dryer: 'DRYER',
    Fireplace: 'FIREPLACE',
    Torch: 'TORCH',
    FreeStandingHeater: 'FREE_STANDING_HEATER',
    TileStove: 'TILE_STOVE',
    Heater: 'HEATER',
    Incinerator: 'INCINERATOR',
    Oven: 'OVEN',
    BakingOven: 'BAKING_OVEN',
    ProcessHeaters: 'PROCESS_HEATERS',
    ThermalOxidizer: 'THERMAL_OXIDIZER'
  };
  StationaryDeviceEntryDto.FuelEnum = {
    Bioethanol: 'BIOETHANOL',
    Biodiesel: 'BIODIESEL',
    BiomethaneCompressed: 'BIOMETHANE_COMPRESSED',
    Biopropane: 'BIOPROPANE',
    Biopetrol: 'BIOPETROL',
    RenewablePetrol: 'RENEWABLE_PETROL',
    BiomethaneLiquefied: 'BIOMETHANE_LIQUEFIED',
    Biomethanol: 'BIOMETHANOL',
    BiomassWoodLogs: 'BIOMASS_WOOD_LOGS',
    BiomassWoodPellets: 'BIOMASS_WOOD_PELLETS',
    BiomassWoodChips: 'BIOMASS_WOOD_CHIPS',
    BiomassGrassStraw: 'BIOMASS_GRASS_STRAW',
    Biogas: 'BIOGAS',
    LandfillGas: 'LANDFILL_GAS',
    Coal: 'COAL',
    BurningOil: 'BURNING_OIL',
    Lng: 'LNG',
    Cng: 'CNG',
    Diesel: 'DIESEL',
    Petrol: 'PETROL'
  };
  StationaryDeviceEntryDto.FuelUnitEnum = {
    Litres: 'LITRES',
    Gj: 'GJ',
    Kwh: 'KWH',
    Tonnes: 'TONNES',
    Kg: 'KG'
  };
})(StationaryDeviceEntryDto || (StationaryDeviceEntryDto = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var TopCompaniesByCarbonEmissions;
(function (TopCompaniesByCarbonEmissions) {
  TopCompaniesByCarbonEmissions.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(TopCompaniesByCarbonEmissions || (TopCompaniesByCarbonEmissions = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateCertificatedRenewableEnergyBoughtRequest;
(function (UpdateCertificatedRenewableEnergyBoughtRequest) {
  UpdateCertificatedRenewableEnergyBoughtRequest.ReCertificatesEnum = {
    ReCertificate: 'RE_CERTIFICATE'
  };
  UpdateCertificatedRenewableEnergyBoughtRequest.CertificatedRenewableEnergyBoughtUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(UpdateCertificatedRenewableEnergyBoughtRequest || (UpdateCertificatedRenewableEnergyBoughtRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateElectricityConsumptionRequest;
(function (UpdateElectricityConsumptionRequest) {
  UpdateElectricityConsumptionRequest.EnergySupplierEnum = {
    Enea: 'ENEA',
    Energa: 'ENERGA',
    Eon: 'EON',
    Tauron: 'TAURON',
    Pge: 'PGE',
    Unknown: 'UNKNOWN'
  };
})(UpdateElectricityConsumptionRequest || (UpdateElectricityConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateEsgConferenceRequest;
(function (UpdateEsgConferenceRequest) {
  UpdateEsgConferenceRequest.StatusEnum = {
    Created: 'CREATED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Finished: 'FINISHED',
    Archived: 'ARCHIVED'
  };
})(UpdateEsgConferenceRequest || (UpdateEsgConferenceRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateFugitiveEmissionRequest;
(function (UpdateFugitiveEmissionRequest) {
  UpdateFugitiveEmissionRequest.CoolingDeviceTypeEnum = {
    VehicleAirConditioner: 'VEHICLE_AIR_CONDITIONER',
    RefrigerationDevice: 'REFRIGERATION_DEVICE',
    StationaryAirConditioner: 'STATIONARY_AIR_CONDITIONER',
    IndustrialProcessCooling: 'INDUSTRIAL_PROCESS_COOLING',
    RefrigeratedTransport: 'REFRIGERATED_TRANSPORT',
    RefrigeratedWarehouses: 'REFRIGERATED_WAREHOUSES'
  };
  UpdateFugitiveEmissionRequest.RefrigerantTypeEnum = {
    R401A: 'R401A',
    R401B: 'R401B',
    R401C: 'R401C',
    R402A: 'R402A',
    R402B: 'R402B',
    R403A: 'R403A',
    R403B: 'R403B',
    R404A: 'R404A',
    R405A: 'R405A',
    R406A: 'R406A',
    R407A: 'R407A',
    R407B: 'R407B',
    R407C: 'R407C',
    R407D: 'R407D',
    R407E: 'R407E',
    R407F: 'R407F',
    R408A: 'R408A',
    R409A: 'R409A',
    R409B: 'R409B',
    R410A: 'R410A',
    R410B: 'R410B',
    R411A: 'R411A',
    R411B: 'R411B',
    R412A: 'R412A',
    R413A: 'R413A',
    R414A: 'R414A',
    R414B: 'R414B',
    R415A: 'R415A',
    R415B: 'R415B',
    R416A: 'R416A',
    R417A: 'R417A',
    R417B: 'R417B',
    R417C: 'R417C',
    R418A: 'R418A',
    R419A: 'R419A',
    R419B: 'R419B',
    R420A: 'R420A',
    R421A: 'R421A',
    R421B: 'R421B',
    R422A: 'R422A',
    R422B: 'R422B',
    R422C: 'R422C',
    R422D: 'R422D',
    R422E: 'R422E',
    R423A: 'R423A',
    R424A: 'R424A',
    R425A: 'R425A',
    R426A: 'R426A',
    R427A: 'R427A',
    R428A: 'R428A',
    R429A: 'R429A',
    R430A: 'R430A',
    R431A: 'R431A',
    R432A: 'R432A',
    R433A: 'R433A',
    R433B: 'R433B',
    R433C: 'R433C',
    R434A: 'R434A',
    R435A: 'R435A',
    R436A: 'R436A',
    R436B: 'R436B',
    R437A: 'R437A',
    R438A: 'R438A',
    R439A: 'R439A',
    R440A: 'R440A',
    R441A: 'R441A',
    R442A: 'R442A',
    R443A: 'R443A',
    R444A: 'R444A',
    R445A: 'R445A',
    R500: 'R500',
    R501: 'R501',
    R502: 'R502',
    R503: 'R503',
    R504: 'R504',
    R505: 'R505',
    R506: 'R506',
    R507A: 'R507A',
    R508A: 'R508A',
    R508B: 'R508B',
    R509A: 'R509A',
    R510A: 'R510A',
    R511A: 'R511A',
    R512A: 'R512A',
    CarbonDioxide: 'CARBON_DIOXIDE',
    Methane: 'METHANE',
    NitrousOxide: 'NITROUS_OXIDE',
    Hfc23: 'HFC_23',
    Hfc32: 'HFC_32',
    Hfc41: 'HFC_41',
    Hfc125: 'HFC_125',
    Hfc134: 'HFC_134',
    Hfc134A: 'HFC_134A',
    Hfc143: 'HFC_143',
    Hfc143A: 'HFC_143A',
    Hfc152A: 'HFC_152A',
    Hfc227Ea: 'HFC_227EA',
    Hfc236Fa: 'HFC_236FA',
    Hfc245Fa: 'HFC_245FA',
    Hfc43I0Mee: 'HFC_43_I0MEE',
    PerfluoromethanePfc14: 'PERFLUOROMETHANE_PFC_14',
    PerfluoroethanePfc116: 'PERFLUOROETHANE_PFC_116',
    PerfluoropropanePfc218: 'PERFLUOROPROPANE_PFC_218',
    PerfluorocyclobutanePfc318: 'PERFLUOROCYCLOBUTANE_PFC_318',
    PerfluorobutanePfc3110: 'PERFLUOROBUTANE_PFC_3_1_10',
    PerfluoropentanePfc4112: 'PERFLUOROPENTANE_PFC_4_1_12',
    PerfluorohexanePfc5114: 'PERFLUOROHEXANE_PFC_5_1_14',
    Pfc9118: 'PFC_9_1_18',
    Perfluorocyclopropane: 'PERFLUOROCYCLOPROPANE',
    SulphurHexaflourideSf6: 'SULPHUR_HEXAFLOURIDE_SF6',
    Hfc152: 'HFC_152',
    Hfc161: 'HFC_161',
    Hfc236Cb: 'HFC_236CB',
    Hfc236Ea: 'HFC_236EA',
    Hfc245Ca: 'HFC_245CA',
    Hfc365Mfc: 'HFC_365MFC',
    NitrogenTrifluoride: 'NITROGEN_TRIFLUORIDE',
    Cfc11R11Trichlorofluoromethane: 'CFC_11_R11_TRICHLOROFLUOROMETHANE',
    Cfc12R12Dichlorodifluoromethane: 'CFC_12_R12_DICHLORODIFLUOROMETHANE',
    Cfc13: 'CFC_13',
    Cfc113: 'CFC_113',
    Cfc114: 'CFC_114',
    Cfc115: 'CFC_115',
    Halon1211: 'HALON_1211',
    Halon1301: 'HALON_1301',
    Halon2402: 'HALON_2402',
    CarbonTetrachloride: 'CARBON_TETRACHLORIDE',
    MethylBromide: 'METHYL_BROMIDE',
    MethylChloroform: 'METHYL_CHLOROFORM',
    Hcfc22R22Chlorodifluoromethane: 'HCFC_22_R22_CHLORODIFLUOROMETHANE',
    Hcfc123: 'HCFC_123',
    Hcfc124: 'HCFC_124',
    Hcfc141B: 'HCFC_141B',
    Hcfc142B: 'HCFC_142B',
    Hcfc225Ca: 'HCFC_225CA',
    Hcfc225Cb: 'HCFC_225CB',
    Hcfc21: 'HCFC_21',
    Hfe125: 'HFE_125',
    Hfe134: 'HFE_134',
    Hfe143A: 'HFE_143A',
    Hcfe235Da2: 'HCFE_235DA2',
    Hfe245Cb2: 'HFE_245CB2',
    Hfe245Fa2: 'HFE_245FA2',
    Hfe254Cb2: 'HFE_254CB2',
    Hfe347Mcc3: 'HFE_347MCC3',
    Hfe347Pcf2: 'HFE_347PCF2',
    Hfe356Pcc3: 'HFE_356PCC3',
    Hfe449SlHfe7100: 'HFE_449SL_HFE_7100',
    Hfe569Sf2Hfe7200: 'HFE_569SF2_HFE_7200',
    Hfe4310Pccc124HGalden1040X: 'HFE_43_10PCCC124_H_GALDEN1040X',
    Hfe236Ca12Hg10: 'HFE_236CA12_HG_10',
    Hfe338Pcc13Hg01: 'HFE_338PCC13_HG_01',
    TrifluoromethylSulphurPentafluoride: 'TRIFLUOROMETHYL_SULPHUR_PENTAFLUORIDE',
    Pfpmie: 'PFPMIE',
    Dimethylether: 'DIMETHYLETHER',
    MethyleneChloride: 'METHYLENE_CHLORIDE',
    MethylChloride: 'METHYL_CHLORIDE',
    R290Propane: 'R290_PROPANE',
    R600AIsobutane: 'R600A_ISOBUTANE',
    R600Butane: 'R600_BUTANE',
    R601Pentane: 'R601_PENTANE',
    R601AIsopentane: 'R601A_ISOPENTANE',
    R170Ethane: 'R170_ETHANE',
    R1270Propene: 'R1270_PROPENE',
    R1234Yf: 'R1234YF',
    R1234Ze: 'R1234ZE'
  };
})(UpdateFugitiveEmissionRequest || (UpdateFugitiveEmissionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateHeatConsumptionRequest;
(function (UpdateHeatConsumptionRequest) {
  UpdateHeatConsumptionRequest.CountryEnum = {
    Pl: 'PL',
    De: 'DE'
  };
})(UpdateHeatConsumptionRequest || (UpdateHeatConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateManualCompaniesCO2ScoreRequest;
(function (UpdateManualCompaniesCO2ScoreRequest) {
  UpdateManualCompaniesCO2ScoreRequest.Co2ScoreMassUnitEnum = {
    Kg: 'kg',
    Mg: 'Mg'
  };
})(UpdateManualCompaniesCO2ScoreRequest || (UpdateManualCompaniesCO2ScoreRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateManufacturedBottlesRequest;
(function (UpdateManufacturedBottlesRequest) {
  UpdateManufacturedBottlesRequest.BottleVolumeEnum = {
    NoRush533Ml: 'NO_RUSH_533ML',
    IsotonicRush750Ml: 'ISOTONIC_RUSH_750ML',
    PowerBooster1000Ml: 'POWER_BOOSTER_1000ML',
    EnergyBlast500Ml: 'ENERGY_BLAST_500ML',
    LightningBolt250Ml: 'LIGHTNING_BOLT_250ML',
    SuperCharge1500Ml: 'SUPER_CHARGE_1500ML',
    MegaBoost1750Ml: 'MEGA_BOOST_1750ML',
    UltraPower2000Ml: 'ULTRA_POWER_2000ML',
    ExtremeEnergy5000Ml: 'EXTREME_ENERGY_5000ML'
  };
})(UpdateManufacturedBottlesRequest || (UpdateManufacturedBottlesRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateRemoteWorkRequest;
(function (UpdateRemoteWorkRequest) {
  UpdateRemoteWorkRequest.RemoteWorkEnum = {
    PartTime14: 'PART_TIME_1_4',
    PartTime12: 'PART_TIME_1_2',
    FullTime: 'FULL_TIME',
    OneAndAHalfTime: 'ONE_AND_A_HALF_TIME'
  };
})(UpdateRemoteWorkRequest || (UpdateRemoteWorkRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateRenewableEnergyConsumptionRequest;
(function (UpdateRenewableEnergyConsumptionRequest) {
  UpdateRenewableEnergyConsumptionRequest.InstallationTypeEnum = {
    Photovoltaic: 'PHOTOVOLTAIC',
    Hydroelectric: 'HYDROELECTRIC',
    WindTurbine: 'WIND_TURBINE',
    OtherInstallation: 'OTHER_INSTALLATION'
  };
  UpdateRenewableEnergyConsumptionRequest.RenewableEnergyConsumedUnitEnum = {
    KWh: 'kWh',
    Mwh: 'MWh'
  };
})(UpdateRenewableEnergyConsumptionRequest || (UpdateRenewableEnergyConsumptionRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateStationaryDeviceRequest;
(function (UpdateStationaryDeviceRequest) {
  UpdateStationaryDeviceRequest.HeatingDeviceTypeEnum = {
    Boiler: 'BOILER',
    Generator: 'GENERATOR',
    Pump: 'PUMP',
    CombustionTurbine: 'COMBUSTION_TURBINE',
    Compressor: 'COMPRESSOR',
    Dryer: 'DRYER',
    Fireplace: 'FIREPLACE',
    Torch: 'TORCH',
    FreeStandingHeater: 'FREE_STANDING_HEATER',
    TileStove: 'TILE_STOVE',
    Heater: 'HEATER',
    Incinerator: 'INCINERATOR',
    Oven: 'OVEN',
    BakingOven: 'BAKING_OVEN',
    ProcessHeaters: 'PROCESS_HEATERS',
    ThermalOxidizer: 'THERMAL_OXIDIZER'
  };
  UpdateStationaryDeviceRequest.FuelEnum = {
    Bioethanol: 'BIOETHANOL',
    Biodiesel: 'BIODIESEL',
    BiomethaneCompressed: 'BIOMETHANE_COMPRESSED',
    Biopropane: 'BIOPROPANE',
    Biopetrol: 'BIOPETROL',
    RenewablePetrol: 'RENEWABLE_PETROL',
    BiomethaneLiquefied: 'BIOMETHANE_LIQUEFIED',
    Biomethanol: 'BIOMETHANOL',
    BiomassWoodLogs: 'BIOMASS_WOOD_LOGS',
    BiomassWoodPellets: 'BIOMASS_WOOD_PELLETS',
    BiomassWoodChips: 'BIOMASS_WOOD_CHIPS',
    BiomassGrassStraw: 'BIOMASS_GRASS_STRAW',
    Biogas: 'BIOGAS',
    LandfillGas: 'LANDFILL_GAS',
    Coal: 'COAL',
    BurningOil: 'BURNING_OIL',
    Lng: 'LNG',
    Cng: 'CNG',
    Diesel: 'DIESEL',
    Petrol: 'PETROL'
  };
  UpdateStationaryDeviceRequest.FuelUnitEnum = {
    Litres: 'LITRES',
    Gj: 'GJ',
    Kwh: 'KWH',
    Tonnes: 'TONNES',
    Kg: 'KG'
  };
})(UpdateStationaryDeviceRequest || (UpdateStationaryDeviceRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var UpdateVehicleRequest;
(function (UpdateVehicleRequest) {
  UpdateVehicleRequest.FuelEnum = {
    Lpg: 'LPG',
    Petrol: 'PETROL',
    Diesel: 'DIESEL',
    Cng: 'CNG',
    Lng: 'LNG',
    Biodiesel: 'BIODIESEL',
    Bioethanol: 'BIOETHANOL'
  };
  UpdateVehicleRequest.VehicleCategoryEnum = {
    PassengerCar: 'PASSENGER_CAR',
    Truck: 'TRUCK',
    DeliveryVehicle: 'DELIVERY_VEHICLE',
    Motorcycle: 'MOTORCYCLE',
    Tractor: 'TRACTOR',
    Forklift: 'FORKLIFT',
    Lift: 'LIFT',
    Motorboat: 'MOTORBOAT'
  };
  UpdateVehicleRequest.FuelUnitEnum = {
    Litres: 'LITRES',
    Tones: 'TONES',
    KwhNetto: 'KWH_NETTO',
    KwhBrutto: 'KWH_BRUTTO'
  };
})(UpdateVehicleRequest || (UpdateVehicleRequest = {}));

/**
 * ESG Calculator API
 * Kalkulator ESG
 *
 * Contact: mobiletract@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var VehicleEntryDto;
(function (VehicleEntryDto) {
  VehicleEntryDto.FuelEnum = {
    Lpg: 'LPG',
    Petrol: 'PETROL',
    Diesel: 'DIESEL',
    Cng: 'CNG',
    Lng: 'LNG',
    Biodiesel: 'BIODIESEL',
    Bioethanol: 'BIOETHANOL'
  };
  VehicleEntryDto.VehicleCategoryEnum = {
    PassengerCar: 'PASSENGER_CAR',
    Truck: 'TRUCK',
    DeliveryVehicle: 'DELIVERY_VEHICLE',
    Motorcycle: 'MOTORCYCLE',
    Tractor: 'TRACTOR',
    Forklift: 'FORKLIFT',
    Lift: 'LIFT',
    Motorboat: 'MOTORBOAT'
  };
  VehicleEntryDto.FuelUnitEnum = {
    Litres: 'LITRES',
    Tones: 'TONES',
    KwhNetto: 'KWH_NETTO',
    KwhBrutto: 'KWH_BRUTTO'
  };
})(VehicleEntryDto || (VehicleEntryDto = {}));
class ApiModule {
  static forRoot(configurationFactory) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: Configuration,
        useFactory: configurationFactory
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static ɵfac = function ApiModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i1.HttpClient, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApiModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: [],
      providers: []
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APIS, AddCertificatedRenewableEnergyBoughtRequest, AddElectricityConsumptionRequest, AddEsgConferenceEntryRequest, AddFugitiveEmissionRequest, AddHeatConsumptionRequest, AddManualCompaniesCO2ScoreRequest, AddManufacturedBottlesRequest, AddRemoteWorkRequest, AddRenewableEnergyConsumptionRequest, AddStationaryDeviceRequest, AddVehicleRequest, ApiError, ApiModule, BASE_PATH, COLLECTION_FORMATS, CertificatedRenewableEnergyBoughtEntryDto, CertificatedRenewableEnergyBoughtEntryService, Configuration, CurrentAndOzeRatioSummary, DictionaryService, ESGConferenceService, ElectricityConsumptionEntryDto, ElectricityConsumptionEntryService, EsgConference, EsgConferenceEntry, FugitiveEmissionEntryDto, FugitiveEmissionsEntryService, GetSummaryAllScopesRequest, GetSummaryFirstScopeRequest, GetSummarySecondScopeRequest, GetSummaryThirdScopeRequest, HeatConsumptionEntryDto, HeatConsumptionEntryService, ManualCompaniesCO2ScoreEntryResponse, ManualCompaniesCO2ScoreEntryService, ManufacturedBottlesEntryDto, ManufacturedBottlesEntryService, PatchElectricityConsumptionRequest, PatchFugitiveEmissionRequest, PatchHeatConsumptionRequest, PatchManufacturedBottlesRequest, PatchRemoteWorkRequest, PatchRenewableEnergyConsumptionRequest, PatchStationaryDeviceRequest, PatchVehicleRequest, RemoteWorkEntryDto, RemoteWorkEntryService, RenewableEnergyConsumptionEntryDto, RenewableEnergyConsumptionEntryService, ReportEntryService, StationaryDeviceEntryDto, StationaryDeviceEntryService, SummaryService, TopCompaniesByCarbonEmissions, UpdateCertificatedRenewableEnergyBoughtRequest, UpdateElectricityConsumptionRequest, UpdateEsgConferenceRequest, UpdateFugitiveEmissionRequest, UpdateHeatConsumptionRequest, UpdateManualCompaniesCO2ScoreRequest, UpdateManufacturedBottlesRequest, UpdateRemoteWorkRequest, UpdateRenewableEnergyConsumptionRequest, UpdateStationaryDeviceRequest, UpdateVehicleRequest, VehicleEntryDto, VehicleEntryService };
