{
  "name": "ekowitryna-esq-calculator",
  "version": "0.0.5-dev",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "doc": "npx compodoc -p tsconfig.app.json -s"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/localize": "^18.2.8",
    "@angular/material": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@ekowitryna/ekowitryna-esg-calculator-angular-api": "^1.0.25",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/free-solid-svg-icons": "^6.6.0",
    "@mobiletract/ngx-generic-table": "0.0.73-dev",
    "@ngrx/component-store": "^18.1.0",
    "@ngrx/effects": "^18.1.0",
    "@ngrx/store": "^18.1.0",
    "@ngrx/store-devtools": "^18.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.34.0",
    "@sentry/tracing": "^7.119.2",
    "angular-google-tag-manager": "^1.10.0",
    "angularx-qrcode": "^18.0.2",
    "chart.js": "^4.4.4",
    "echarts": "^5.5.1",
    "flag-icons": "^7.2.3",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.6",
    "ngx-echarts": "^18.0.0",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "rxjs": "~7.8.0",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@compodoc/compodoc": "^1.1.25",
    "@types/jasmine": "~5.1.0",
    "autoprefixer": "^10.4.20",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.47",
    "tailwindcss": "^3.4.13",
    "typescript": "~5.4.2"
  }
}
